$(document).ready(() => {

    const applyAnswer    = $('.btn-apply');
    let cookie           = [];

    applyAnswer.click(function () {
        const element = $(this);
        const question = $(`#question-${element.attr('data-post-id')}`);

        if ( element.attr('data-index') != ''  ) {
            $.ajax({
                url: '/wp-content/themes/mytheme/handlers/do-voicing.php',
                data: {
                    post_id: element.attr('data-post-id'),
                    meta_id: element.attr('data-index')
                },
                method: 'post',
                success: function () {

                    cookie.push( $.cookie('post_voicing') , element.attr('data-post-id') );

                    $.cookie('post_voicing', cookie );

                    question.fadeOut();
                },
                error: ( response ) => console.error( response )
            })
        }

    });


    const voicingTrigger = $('.answer');

    voicingTrigger.click(function () {
        const element = $(this);

        voicingTrigger.removeClass('focusing-question');
        element.addClass('focusing-question');

        applyAnswer.attr( 'data-index', element.attr('data-index'));
    });



    const resultBlock = $('.result-answer');

    $.each( resultBlock, function() {
        const result = $(this).attr('data-result');
        $(this).animate({
            width: result + '%'
        }, 1000)
    });


    /**
     * Question scroll for projector
     */
    const back            = $('.back');
    const next            = $('.next');
    const sliderContainer = $('.slider-list');
    const listContainer   = $('.slider-container');
    const countPosts      = $('#post-count').val();
    const questions       = sliderContainer.find( '.question-container' );

    let offset            = 0;
    let countPost         = 1;

    next.click(function() {

        if ( countPost < countPosts ) {

            const height = $(questions[countPost]).height() + 70;

            swapQuestion(-100, height );
            countPost++;
        }

    });

    back.click(function() {

        if ( countPost > 1 ) {

            const height = $(questions[countPost - 2]).height() + 70;

            swapQuestion(100, height );
            countPost--;
        }

    });


    function swapQuestion( newOffset, height ) {

        console.log( height );

        offset += newOffset;

        sliderContainer.css({
            transition: '.5s ease-in-out',
            transform: `translateX(${ offset }%)`,
        });

        listContainer.css({
            transition: '.5s ease-in-out',
            height: height + 'px'
        })


    }

});
