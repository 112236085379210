$(document).ready( () => {
    $('.lazy-scroll').click(function() {
        let _href = $(this).attr("href");

        $('html, body').animate({
            scrollTop: ($(_href).offset().top + -100) +'px'
        }, 2000);

        return false;
    });

    $('.header__navbar').find('a').click(function () {
        let _href = $(this).attr("href");

        $('html, body').animate({
            scrollTop: ($(_href).offset().top + -100) +'px'
        }, 2000);

        return false;
    })
});