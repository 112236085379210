import './js/main'
import './js/modal'
import './js/scroll'
import './js/menu'
import './js/admin-logic-handler'
import './js/questions'

/**
 * Style's is importing here
 */
import './sass/style.sass'
