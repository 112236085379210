$(document).ready( () => {
    const btnActivation = $('.btn-activation');

    btnActivation.click(function () {
        const id = $(this).attr('data-id');

        $.ajax({
            url: '/wp-content/themes/mytheme/handlers/admin-logic.php',
            method: 'post',
            data: {
                id: id,
                status: 'publish'
            },
            success: ( response ) => {
                window.location.href = '/questions/#post-' + id;
                console.log( response );
            },
            error: ( response ) => {
                console.error( response );
            }
        })
    });


    const btnClose = $('.btn-closing');

    btnClose.click(function () {
        const id = $(this).attr('data-id');

        $.ajax({
            url: '/wp-content/themes/mytheme/handlers/admin-logic.php',
            method: 'post',
            data: {
                id: id,
                status: 'draft'
            },
            success: ( response ) => {
                window.location.href = '/questions/#post-' + id;
                console.log( response );
            },
            error: ( response ) => {
                console.error( response );
            }
        })
    });


    const btnClean = $('.btn-clean');

    btnClean.click(function () {
        const element = $(this);

        $.ajax({
            url: '/wp-content/themes/mytheme/handlers/clean-voicing.php',
            data: {
                post_id: element.attr('data-id')
            },
            method: 'post',
            success: ( response ) => {window.location.href = '/questions/'; console.log('hi')} ,
            error: ( response ) => console.error( response )
        })

    });
});
