$(document).ready(function () {
    const overlay    = $('#overlay');
    const modal      = $('.modal-simple');
    const closeModal = $('#hide-modal');
    const openModal  = $('.btn-show-modal');

    openModal.click( () => {

        const name     = $('#sign-name').val();
        const tel      = $('#sign-tel').val();

        const formName = $('#name');
        const formTel  = $('#tel');

        formName.val( name );
        formTel.val( tel );

        overlay.css('display', 'block');

        setTimeout(() => {
            overlay.css({
                transition: '.2s ease-in-out',
                opacity: 1
            });

            modal.css({
                transition: '.2s ease-in-out',
                transform: 'scale(1)'
            });
        }, 100)

    });

    closeModal.click( () => {
        overlay.css({
            transition: '.2s ease-in-out',
            opacity: 0
        });

        modal.css({
            transition: '.2s ease-in-out',
            transform: 'scale(0)'
        });

        setTimeout(() => {
            overlay.css('display', 'none');
        }, 200)
    });

    overlay.click( event => {
        if ( event.target.id === 'overlay' ) {
            overlay.css({
                transition: '.2s ease-in-out',
                opacity: 0
            });

            modal.css({
                transition: '.2s ease-in-out',
                transform: 'scale(0)'
            });

            setTimeout(() => {
                overlay.css('display', 'none');
            }, 200)
        }
    });


    /**
     * Validate ======================
     */

    const btnSend = $('.btn-send');

    btnSend.click(function () {
        let fields = $('.validate');

        validate( fields );
    });

    function validate( fields = [] ) {
        $.each( fields, function () {
            let value = $(this).val();
            let input = $(this);

            if ( value == '' && input.attr('type') !== 'email' || input.attr('type') === 'tel' && value.length < 16 ) {
                input.css({
                    transition: '.1s linear',
                    border: '1px solid red'
                });
            }

            else {
                input.css('border', '1px solid #000');
            }
        });
    }

    $('input[type="tel"]').mask('+7-999 999 99 99');
    $('input[name="date"]').mask('00/00/0000');

});