$(document).ready(function () {
    document.addEventListener( 'wpcf7mailsent', function( event ) {
        if ( event.detail.contactFormId == 7 ) {
            $('.body__block-form').fadeOut();
            setTimeout( () => {
                $('.modal-success').fadeIn();
            }, 200 );

            setTimeout( () => {
                $('#overlay').fadeOut();
            }, 5000 )
        }
    }, false );


    /**
     * Show more btn's
     */
    $('#show-more-speakers').click( () => {
        $('#show-more-speakers').fadeOut();

        $('.sub-speaker').slideToggle();
    });

    $('#show-more-program').click( () => {
        $('#show-more-program').fadeOut();

        $('.program-block').addClass('has-after');
        $('.program-block').slideToggle();
    });

});