$(document).ready(function () {

    const mobileMenu = $('.mobile-menu');
    const container  = $('.menu__content');
    const before     = $('.menu-before');
    const items      = $('.animate__items').find('li');
    const closeMenu  = $('#close-menu');
    const bgMobile   = $('.bg-mobile');
    const telNumber  = $('.mobile-menu__tel');
    const btn        = $('.btn-mobile-menu');

    let transition = 4;

    const hamburger  = $('.header__hamburger');

    hamburger.click(() => {
        mobileMenu.css('display', 'block');

        before.css({
            transition: 'all .3s ease-in-out',
            transform: 'scale(20)',
            opacity: 1
        });

        setTimeout(function () {
            btn.css({
                transition: 'all .8s ease-in-out',
                padding: '6px 18px 9px 18px'
            });

            closeMenu.css({
                transition: 'all .8s ease-in-out',
                transform: 'rotate(180deg)'
            });

            bgMobile.css({
                transition: 'all .8s ease-in-out',
                opacity: 1,
                left: 0
            });

            $.each(items, function () {
                let item = this;
                setTimeout(function () {
                    $(item).css({
                        transition:  '.'+ transition +'s ease-in-out',
                        left: 0,
                        opacity: 1
                    });
                    transition++;
                }, 100);

            });

            setTimeout(function () {
                telNumber.css({
                    transition:  '.'+ transition +'s ease-in-out',
                    left: 0,
                    opacity: 1
                })
            }, 400);

        }, 50);

        container.css('display', 'block');
    });



    closeMenu.click(() => {
        before.css({
            transition: 'all .3s ease-in-out',
            transform: 'scale(0)',
            opacity: 0
        });

        setTimeout(function () {
            btn.css({
                transition: 'all .8s ease-in-out',
                padding: '6px 0 9px 18px'
            });

            closeMenu.css({
                transition: 'all .8s ease-in-out',
                transform: 'rotate(0)'
            });

            bgMobile.css({
                transition: 'all .8s ease-in-out',
                opacity: 0,
                left: '-100px'
            });

            $.each(items, function () {
                let item = this;
                setTimeout(function () {
                    $(item).css({
                        transition:  '.'+ transition +'s ease-in-out',
                        left: '100px',
                        opacity: 0
                    });
                    transition++;
                }, 100);

            });

            setTimeout(function () {
                telNumber.css({
                    transition:  '.'+ transition +'s ease-in-out',
                    left: '100px',
                    opacity: 0
                })
            }, 400);

        }, 50);

        container.css('display', 'none');

        mobileMenu.css('display', 'none');
    });

});